import * as React from 'react'
import { PxlRaisedButton } from '../Button'

export const SinglePostCTA: React.SFC<{
  title: string
  description: string
  label: string
  link: string
  text_color: string
  background_color: string
}> = props => {
  const { text_color: color = '#666666', background_color: backgroundColor = '#F1F1F1' } = props
  return (
    <div className="details details-cta" style={{ backgroundColor, color }}>
      <h3>{props.title}</h3>
      {props.description && <div dangerouslySetInnerHTML={{ __html: props.description }} />}
      <p className="action">
        <PxlRaisedButton to={props.link} width={300}>
          {props.label}
        </PxlRaisedButton>
      </p>
    </div>
  )
}
