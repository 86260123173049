import * as React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { keyBy } from 'lodash'
import md5 from 'md5'

import Page from '../components/Page'
import PageBanner from '../components/single/PageBanner'
import IndexLayout from '../layouts'
import { Post } from '../Entities'
import { DisqusThread, DisqusSection } from '../components/single/DisqusThread'
import ContactForm from '../components/contact/section'
import { DataStringError, DataStringContent } from '../components/single/DataStringContent'
import { SinglePostCTA } from '../components/single/PostCtaSection'
import { Helmet } from 'react-helmet'
import { SinglePostAuthor } from '../components/single/PostAuthor'
import { BaseEntityShare } from '../components/single/BaseEntityShare'

interface PageTemplateProps {
  data: {
    post: Post
    alt: { uid: string }
    site: { siteMetadata: { siteUrl: string } }
  }
}

const PostTemplate: React.SFC<PageTemplateProps> = ({ data }) => {
  const slices = keyBy(data.post.data.body, 'slice_type')
  return (
    <IndexLayout>
      <Page id="post-page" className="page-format-1">
        <PageBanner
          name={data.post.data.title.text}
          cover_picture_element={
            <Img
              fluid={data.post.data.cover_picture.localFile.childImageSharp.fluid}
              style={{ height: '100%' }}
            />
          }
          description={data.post.data.subtitle.text}
          return_route={'/blog'}
          alt_lang_route={data.alt ? `/project/${data.alt.uid}` : undefined}
        />

        <article>
          <DataStringError>
            <DataStringContent dataString={data.post.dataString} />
          </DataStringError>

          <BaseEntityShare dir="post" path={data.post.uid} title={data.post.data.title.text} />

          <SinglePostAuthor
            name={data.post.data.author}
            about={
              'Designer de Produto e fundador da 7bits (https://7bits.cc), uma consultoria especializada em UX/UI design'
            }
            picture={`https://www.gravatar.com/avatar/${md5('gabriel@7bits.cc')}?d=identicon&s=48`}
            publishedAt={
              data.post.first_publication_date ? new Date(data.post.first_publication_date) : null
            }
            updatedAt={
              data.post.last_publication_date ? new Date(data.post.last_publication_date) : null
            }
          />

          {slices.call_to_action ? (
            <SinglePostCTA
              title={slices.call_to_action.primary.title.text}
              description={slices.call_to_action.primary.content.html}
              label={slices.call_to_action.primary.label}
              link={slices.call_to_action.primary.link.slug}
              text_color={slices.call_to_action.primary.text_color}
              background_color={slices.call_to_action.primary.background_color}
            />
          ) : null}
        </article>

        <DisqusSection />
      </Page>

      {/* <ContactForm /> */}

      <Helmet
        title={data.post.data.title.text}
        meta={[
          { name: 'description', content: data.post.data.meta_description },
          {
            name: 'keywords',
            content: `UX Design, UI Design, Startup ${data.post.data.tags
              .map(v => v.tag)
              .join(' ')}`
          },

          { property: 'og:type', content: 'article' },
          {
            property: 'og:title',
            content: data.post.data.title.text
          },
          {
            property: 'og:description',
            content: data.post.data.meta_description
          },
          {
            property: 'og:url',
            content: `${data.site.siteMetadata.siteUrl}/${data.post.uid}`
          },
          {
            property: 'og:image',
            content: data.post.data.cover_picture.localFile.childImageSharp.fixed.src
          },

          { property: 'article:published_time', content: data.post.first_publication_date },
          { property: 'article:modified_time', content: data.post.last_publication_date },
          {
            property: 'article:author',
            content: data.post.data.author
          },
          {
            property: 'article:section',
            content: data.post.data.article_section
          },
          {
            property: 'article:tag',
            content: data.post.data.tags.map(v => v.tag).join(' ')
          },
          {
            property: 'author',
            content: data.post.data.author
          }
        ]}
      />
    </IndexLayout>
  )
}

export default PostTemplate

export const query = graphql`
  query PrismicPost($uid: String!, $link: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    alt: prismicBlogPost(uid: { ne: $uid }, data: { link: { eq: $link } }) {
      uid
      data {
        link
      }
    }
    post: prismicBlogPost(uid: { eq: $uid }) {
      dataString
      first_publication_date
      last_publication_date
      uid
      data {
        link
        author
        meta_description
        article_section
        title {
          text
        }
        subtitle {
          text
        }
        tags {
          tag
        }
        author
        content {
          background_color
        }
        cover_picture {
          localFile {
            childImageSharp {
              fixed(width: 1024) {
                src
              }
              fluid(maxWidth: 1900, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        body {
          slice_type
          primary {
            title {
              html
              text
            }
            content {
              html
              text
            }
            label
            link {
              type
              slug
            }
            background_color
            text_color
          }
        }
      }
    }
  }
`
