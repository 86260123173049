import * as React from 'react'

function getPublishDate(date: Date) {
  const [ISODate] = date.toISOString().split('T')
  const [year, month, day] = ISODate.split('-')
  return `${day}/${month}/${year}`
}

export const SinglePostAuthor: React.SFC<{
  name: string
  about: string
  picture: string
  publishedAt: Date | null
  updatedAt: Date | null
  textColor?: string
  backgroundColor?: string
}> = props => {
  const { textColor: color = '#666666', backgroundColor = '#F1F1F1' } = props
  return (
    <section
      className="details details-author"
      style={{ backgroundColor, color, paddingTop: '16px' }}
    >
      <div style={{ display: 'flex', maxWidth: '800px' }}>
        <div style={{ flex: '0 0 48px', margin: '0 24px' }}>
          <img src={props.picture} style={{ borderRadius: 500 }} />
        </div>
        <div style={{ flex: '0 1 80%' }}>
          <address>
            <h3 style={{ textAlign: 'left', margin: 0 }}>{props.name}</h3>
            <div style={{ margin: '8px 0' }}>{props.about}</div>
          </address>
          <div>
            <small>
              {props.publishedAt && (
                <>
                  Publicado em{' '}
                  <time dateTime={props.publishedAt.toISOString()}>
                    {getPublishDate(props.publishedAt)}
                  </time>
                  .{' '}
                </>
              )}
              {props.updatedAt &&
                props.updatedAt !== props.publishedAt && (
                  <>Atualizado em {getPublishDate(props.updatedAt)}</>
                )}
            </small>
          </div>
        </div>
      </div>
    </section>
  )
}
