import * as React from 'react'
import { RichText } from 'prismic-reactjs'
import { Post, DynamicContent } from '../../Entities'
import { linkResolver } from 'src/prismic'

export const DataStringContent: React.SFC<{
  dataString: Post['dataString']
}> = ({ dataString }) => {
  const parsed = JSON.parse(dataString)
  return parsed.content.map((block: DynamicContent, i: number) => (
    <section key={i} className="details">
      {block.content.html ? (
        <div dangerouslySetInnerHTML={{ __html: block.content.html }} />
      ) : (
        RichText.render(block.content, linkResolver)
      )}
    </section>
  ))
}

export class DataStringError extends React.PureComponent {
  static getDerivedStateFromError() {
    return { debug: false, hasError: true }
  }
  state = { debug: false, hasError: false }

  componentDidCatch(error: Error, info: any) {
    if (this.state.debug) console.error(error, info)
  }
  render() {
    return this.state.hasError ? (
      <>
        <h3>Error</h3>
        <p>Sorry, we have found an error parsing the article.</p>
      </>
    ) : (
      this.props.children
    )
  }
}
