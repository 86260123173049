import * as React from 'react'
import { Language } from '../../state'

const facebookShare = (url: string) => () =>
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 'name', 'width=600,height=400')

const linkedinShare = (url: string) => () =>
  window.open(
    `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
    'name',
    'width=600,height=400'
  )

const twitterShare = (url: string) => () =>
  window.open(
    `https://twitter.com/intent/tweet?text=7bits&url=${url}`,
    'name',
    'width=600,height=400'
  )

export const BaseEntityShare: React.SFC<{
  dir: string
  path: string
  title: string
}> = props => {
  const url = encodeURIComponent(`http://7bits.cc/${props.dir}/${props.path}`)
  return (
    <Language.Consumer>
      {language => (
        <section className="details share">
          <p>
            {language.translate('ACTION_SHARE')}:{' '}
            <>
              <a onClick={facebookShare(url)}>Facebook</a>,{' '}
              <a onClick={linkedinShare(url)}>Linkedin</a>,{' '}
              <a onClick={twitterShare(url)}>Twitter</a>
            </>
          </p>
        </section>
      )}
    </Language.Consumer>
  )
}
