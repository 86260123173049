import * as React from 'react'
import { Link } from 'gatsby'
import { Language } from '../../state/index'
import { PageLanguageFlags } from '../LanguageFlags'

interface PageBannerProps {
  cover_picture_element?: React.ReactElement<any>
  return_route: string
  alt_lang_route?: string
  name: string
  description: string
}

const PageBanner: React.SFC<PageBannerProps> = ({
  cover_picture_element,
  return_route,
  alt_lang_route,
  name,
  description
}) => {
  return (
    <Language.Consumer>
      {language => (
        <header className="banner">
          {cover_picture_element || null}

          <div className="content">
            <div className="action">
              <Link to={return_route} className="button-light-alpha">
                {language.translate('ACTION_BACK')}
              </Link>

              <PageLanguageFlags alt_lang_route={alt_lang_route} />
            </div>

            <div className="info">
              <div style={{ textAlign: 'center' }}>
                <h1>{name}</h1>
                {description}
              </div>
            </div>
          </div>
        </header>
      )}
    </Language.Consumer>
  )
}

export default PageBanner
