import * as React from 'react'

export class DisqusThread extends React.PureComponent {
  componentDidMount() {
    if (window.disqus_ready) {
      window.disqus_ready.then(() => {
        if (window.DISQUS) {
          window.DISQUS.reset({
            reload: true,
            config() {
              this.page.url = this.props.location.pathname
              this.page.identifier = this.props.post ? this.props.post.name : '7bits'
            }
          })
        }
      })
    }
  }
  render() {
    return <div id="disqus_thread" style={{ width: '100%' }} />
  }
}

export const DisqusSection: React.SFC = () => (
  <section className="details" style={{ backgroundColor: 'white', paddingTop: 34 }}>
    <DisqusThread />
  </section>
)
